// Rounded rectangle based on code from http://js-bits.blogspot.com/2010/07/canvas-rounded-corner-rectangles.html
CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius, withStroke, withFill) {
  if (width < (2 * radius)) {
    radius = width / 2
  }
  if (height < (2 * radius)) {
    radius = height / 2
  }

  withStroke = typeof withStroke !== "undefined" ? withStroke : true
  withFill = typeof withFill !== "undefined" ? withFill : true

  this.beginPath()
  this.moveTo(x + radius, y)
  this.lineTo(x + width - radius, y)
  this.quadraticCurveTo(x + width, y, x + width, y + radius)
  this.lineTo(x + width, y + height - radius)
  this.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  this.lineTo(x + radius, y + height)
  this.quadraticCurveTo(x, y + height, x, y + height - radius)
  this.lineTo(x, y + radius)
  this.quadraticCurveTo(x, y, x + radius, y)
  this.closePath()
  if (withStroke) this.stroke()
  if (withFill) this.fill()
}

export var TankDrawer = function (darkMode = false) {
  var left = 50
  var top = 10
  var cornerRadius = 5
  var volumeLineWidth = 2
  var volume_line_overhang = 5
  var textColor = darkMode ? "#fff" : "#000"
  var textWidth = 1
  var textMargin = 10
  var textFont = "12px Arial"
  var textBaseLine = "middle"
  var tankWallColor = "#000"
  var tankWallWidth = 3
  var tankBackgroundColor = "#fff"
  var ctx, width, height, capacity

  var methods = {}

  var toLineOffset = function (volume) {
    var percent_full = 1 - (volume / capacity)
    return height * percent_full
  }

  var drawVolumeFill = function (volume, fill_color, text_align, hideText) {
    ctx.fillStyle = fill_color
    var offset = toLineOffset(volume)
    var fill_top = top + offset
    var fill_height = height - offset
    ctx.roundRect(left, fill_top, width, fill_height, cornerRadius, false, true)
    if (hideText) return
    drawTextAtVolume(volume, text_align)
  }

  var drawVolumeLine = function (volume, line_color, text_align, hideText) {
    var offset = toLineOffset(volume)
    var line_top_pos = top + offset
    ctx.strokeStyle = line_color
    ctx.lineWidth = volumeLineWidth
    ctx.beginPath()
    ctx.moveTo(left - volume_line_overhang, line_top_pos)
    ctx.lineTo(left + width + volume_line_overhang, line_top_pos)
    ctx.stroke()
    if (hideText) return
    drawTextAtVolume(volume, text_align)
  }

  var drawTextAtVolume = function (volume, text_align) {
    ctx.textAlign = text_align
    ctx.lineWidth = textWidth
    ctx.fillStyle = textColor
    var text_left_pos = (text_align == "left" ? left + width + textMargin : left - textMargin)
    var offset = toLineOffset(volume)
    var text_top_pos = top + offset
    ctx.fillText(volume, text_left_pos, text_top_pos)
  }

  var drawTankWalls = function () {
    ctx.strokeStyle = tankWallColor
    ctx.lineWidth = tankWallWidth
    ctx.roundRect(left, top, width, height, cornerRadius, true, false)
  }

  var drawTankBackground = function () {
    ctx.fillStyle = tankBackgroundColor
    ctx.roundRect(left, top, width, height, cornerRadius, false, true)
  }

  var setTankSize = function (canvas) {
    width = canvas.width - (2 * left)
    height = canvas.height - (4 * top)

    return (!isNaN(width) &&
            width >= 0 &&
            !isNaN(height) &&
            height >= 0)
  }

  var initTank = function (chartSelector) {
    var canvas_id = chartSelector
    if (canvas_id.charAt(0) == "#") {
      canvas_id = canvas_id.slice(1)
    }

    var canvas = document.getElementById(canvas_id)
    if (canvas == undefined) {
      return null
    }
    if (!canvas.getContext) {
      document.getElementById(canvas_id).style.display = "none"
      return null
    }
    if (canvas.width <= 0 || canvas.height <= 0) {
      return null
    }

    document.getElementById(canvas_id).style.display = "block"
    ctx = canvas.getContext("2d")
    ctx.font = textFont
    ctx.textBaseline = textBaseLine
    if (!setTankSize(canvas)) {
      console.log("exiting TankDrawer.draw() because tank size is wrong: width = " + width + ", height = " + height)
      return null
    }
    return canvas
  }

  methods.draw = function (chartSelector, isSmall) {
    const data = document.querySelector(chartSelector).dataset

    if (isSmall) {
      left = 0
      top = 0
      tankWallWidth = 1
    }
    var canvas = initTank(chartSelector)
    if (canvas == null || data == undefined) return

    capacity = data.capacity

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    drawTankBackground()
    drawVolumeFill(Math.round(data.gross), "#ffa500", "right", isSmall)
    drawTankWalls()
    if (isSmall) return
    drawVolumeLine(Math.round(data.overfillThreshold), "#000", "left")
    drawVolumeLine(Math.round(data.lowThreshold), "#000", "left")
  }

  return methods
}
