import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggle () {
    const detailPanels = Array.from(this.element.querySelectorAll("details"))
    const toggleClosed = detailPanels[0].hasAttribute("open") ? true : false

    detailPanels.forEach(panel => {
      if (toggleClosed) {
        panel.removeAttribute("open")
      } else {
        panel.setAttribute("open", true)
      }
    })
  }
}
