import { Controller } from "@hotwired/stimulus"
import CodeMirror from "codemirror"
import "codemirror/mode/htmlmixed/htmlmixed"

export default class extends Controller {
  static targets = ["editor"]

  connect() {
    CodeMirror.fromTextArea(this.editorTarget, {
      lineNumbers: true,
      mode: "htmlmixed"
    })
  }
}
