import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "formField" ]
  static values = { url: String }

  updateParams(event) {
    const element = event.target
    const href = this.buttonTarget.href
    const rawParams = href.split("?")[1]
    const searchParams = new URLSearchParams(rawParams)

    searchParams.set(element.name, element.value)

    this.buttonTarget.href = `${this.urlValue}?${searchParams.toString()}`
  }
}
