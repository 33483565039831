import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    options: Array
  }

  connect() {
    this.element.setAttribute("autocomplete", "none")

    const config = {
      selectOnTab: true,
      openOnFocus: true,
      render: {
        option: this.renderOption,
        item: this.renderOption
      }
    }

    if (this.hasOptionsValue) {
      config.options = this.optionsValue
    }

    new TomSelect(this.element, config)
  }

  renderOption(data, escape) {
    if (data.sub) {
      return `
      <div>
        <div class="text">${escape(data.text)}</div>
        <div class="sub">${escape(data.sub)}</div>
      </div>`}
    else { return `<div>${escape(data.text)}</div>` }
  }
}
