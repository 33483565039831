import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.tomSelect =
      new TomSelect(this.fieldTarget, {
      selectOnTab: true,
      openOnFocus: true,
      hidePlaceholder: true,
      persist: false,
      optgroupField: "organization",
      labelField: "name",
      valueField: "id",
      searchField: ["name", "title", "organization"],
      plugins: {
        remove_button:{
          title:"Remove this user",
        }
      },
      render: {
        option: function(user, escape) {
          return `
          <div class="mb-2 mt-2">
            <div class="h6 mb-0">
              ${escape(user.name)}
            </div>
            <div class="small text-muted">
            ${escape(user.organization)}
            </div>
          </div>`
        },
        item: function(user, escape) {
          return `
          <div>
            ${escape(user.name)}
            <span class="ms-2 small text-muted">${escape(user.organization)}</span>
          </div>`
        },
      },
    })

    const users = JSON.parse(this.fieldTarget.dataset.users)
    users.forEach(user => this.tomSelect.addOption(user))

    const selectedUsers = JSON.parse(this.fieldTarget.dataset.selectedUsers).filter(value => value !== "")
    this.tomSelect.setValue(selectedUsers)
  }

  clearOptions() {
    this.tomSelect.clearOptions()
  }

  clearSelectedValues() {
    this.tomSelect.clear()
  }

  populate(event) {
    const users = JSON.parse(event.target.dataset.users)

    this.clearOptions()
    this.clearSelectedValues()

    users.forEach(user => this.tomSelect.addOption(user))
  }
}
