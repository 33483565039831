import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]
  static values = {
    param: String
  }

  connect() {
    this.params = new URLSearchParams(location.search)
  }

  filter() {
    if (this.fieldTarget.value == "") {
      this.clearFilter()
    } else {
      this.params.set(this.paramValue, this.getFilterValues())
    }

    // clear pagination, otherwise it causes a UI bug
    if (this.params.has("page")) { this.params.delete("page") }

    this.go()
  }

  getFilterValues() {
    if (this.fieldTarget.tagName == "SELECT") {
      const selectedOptions = this.fieldTarget.selectedOptions
      return Array.from(selectedOptions).map((option) => option.value).join(",")
    } else {
      return this.fieldTarget.value
    }
  }

  clearFilter() {
    this.params.delete(this.paramValue)
    this.go()
  }

  go() {
    const urlWithParams = `${location.pathname}?${this.params}`
    const url = this.params.size == 0 ? location.pathname : urlWithParams

    window.Turbo.visit(url)
  }
}
