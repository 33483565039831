import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "button"]

  connect() {
    this.disableButtons()
  }

  evaluate() {
    const completedValues = this.fieldTargets.filter(field => field.value !== "").length
    const totalFields = this.fieldTargets.length

    if (completedValues === totalFields) {
      this.enableButtons()
    } else {
      this.disableButtons()
    }
  }

  disableButtons() {
    this.buttonTargets.forEach(button => {
      button.classList.add("disabled")
      button.disabled = true
    })
  }

  enableButtons() {
    this.buttonTargets.forEach(button => {
      button.classList.remove("disabled")
      button.disabled = false
    })
  }
}
