import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  click() {
    this.resetCheckboxes()
  }

  connect() {
    this.resetCheckboxes()
  }

  resetCheckboxes() {
    if (this.acceptableChecked.length > 0) {
      this.unacceptable.forEach(checkbox => { this.disableCheckbox(checkbox) })
    } else if (this.unacceptableChecked.length > 0) {
      this.acceptable.forEach(checkbox => { this.disableCheckbox(checkbox) })
    } else {
      this.checkboxTargets.forEach(checkbox => { this.enableCheckbox(checkbox) })
    }
  }

  enableCheckbox(checkbox) {
    checkbox.removeAttribute("disabled")
  }

  disableCheckbox(checkbox) {
    checkbox.setAttribute("disabled", true)
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }

  get acceptable() {
    return this.checkboxTargets.filter(checkbox => checkbox.dataset.generatesTask == "false")
  }

  get unacceptable() {
    return this.checkboxTargets.filter(checkbox => checkbox.dataset.generatesTask == "true")
  }

  get acceptableChecked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked && checkbox.dataset.generatesTask == "false")
  }

  get unacceptableChecked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked && checkbox.dataset.generatesTask == "true")
  }
}
