function handleNetworkStateChange() {
  const state = navigator.onLine ? "online" : "offline"
  let message, timeout

  if (state == "offline") {
    message = "Your browser is no longer online, any changes made right now will not be saved."
    timeout = false
  } else {
    message = "Your browser is now back online"
    timeout = true
  }

  const statusMessages = Array.from(document.querySelectorAll(".network-status-message"))
  statusMessages.forEach(message => { message.remove() })

  alert(message)
}

window.addEventListener("online", handleNetworkStateChange)
window.addEventListener("offline", handleNetworkStateChange)
