import { Controller } from "@hotwired/stimulus"
import * as MyTankInfo from "../.././mytankinfo/tank_drawer"

export default class extends Controller {
  static targets = [ "tank" ]

  connect() {
    // Get the value of the data-bs-theme attribute
    const theme = document.documentElement.getAttribute('data-bs-theme');

    // Check if the theme is "dark"
    const isDarkMode = theme === 'dark';

    this.tankTargets.forEach(tank => {
      const drawer = new MyTankInfo.TankDrawer(isDarkMode)
      const tankId = tank.getAttribute("id")
      const drawSmall = tank.dataset.small
      drawer.draw(`#${tankId}`, drawSmall)
    })
  }
}
