import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    url: String,
    valueField: { type: String, default: "id" },
    labelField: { type: String, default: "name" },
  }

  connect() {
    this.element.setAttribute("autocomplete", "none")

    const config = {
      selectOnTab: true,
      openOnFocus: true,
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: ["name", "sub"],
      render: {
        item: this.renderItem,
        option: this.renderOption,
      },
      load: (query, callback) => {
        const self = this
        const url = `${this.urlValue}?search=${encodeURIComponent(query)}`

        if (self.loading > 1) {
          callback()
          return
        }

        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json)
            self.settings.load = null
          }).catch(() => {
            callback()
          })
      },
    }
    new TomSelect(this.element, config)
  }

  renderOption(data, escape) {
    if (data.sub) {
      return `
      <div>
        <div class="text">${escape(data.id)} - ${escape(data.name)}</div>
        <div class="sub">${escape(data.sub)}</div>
      </div>`}
    else { return `<div>${escape(data.name)}</div>` }
  }

  renderItem(data, escape) {
    return `<div>${escape(data.name)}</div>`
  }

  load(query, callback) {
    const self = this
    console.log(this)

    console.log("urlValue", this.urlValue)
    const url = `${this.urlValue}?search=${encodeURIComponent(query)}`
    console.log(url)

    if (self.loading > 1) {
      callback()
      return
    }

    fetch(url)
      .then(response => response.json())
      .then(json => {
        console.log("json", json)
        callback(json)
        self.settings.load = null
      }).catch(() => {
        callback()
      })
  }
}


