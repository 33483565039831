import { Controller } from "@hotwired/stimulus"
import Alert from "bootstrap/js/dist/alert"

export default class extends Controller {
  static values = {
    seconds: { type: Number, default: 10 },
    autohide: { type: Boolean, default: true }
  }

  connect() {
    if (this.autohideValue) {
      const milliseconds = this.secondsValue * 1000
      this.alert = Alert.getOrCreateInstance(this.element)

      setTimeout(() => {
        // if the alert has not already been dismissed
        if (this.alert._element != undefined) {
          this.close()
        }
      }, milliseconds)
    }
  }

  close() {
    this.alert.close()
  }
}
