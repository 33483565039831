import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (navigator.geolocation) {
      const formField = this.element
      const options = {
        enableHighAccuracy: true,
        timeout: 10 * 1000 * 1000,
        maximumAge: 0
      }

      if (formField.value == "" || formField.value == "[]") {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords
          const currentCoordinates = [latitude, longitude]
          formField.value = currentCoordinates
        }, this.error, options)
      }
    }
    else {
      console.warn("Geolocation is not supported in this browser")
    }
  }

  error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }
}
