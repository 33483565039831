import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    urlParamName: String
  }

  refresh() {
    const path = window.location.pathname
    const params = new URLSearchParams()
    const value = this.element.value

    params.set(this.urlParamNameValue, value)
    const url = [path, "?", params.toString()].join("")

    window.Turbo.visit(url)
  }
}
