import { Controller } from "@hotwired/stimulus"
import SignaturePad from "signature_pad"

export default class extends Controller {
  static targets = [ "signature", "clearButton" ]

  connect() {
    const signatureFormField = this.element.querySelector("input[type=\"hidden\"]")
    const signatureCanvas = this.signatureTarget
    const existingSignature = signatureFormField.value

    this.signaturePad = new SignaturePad(signatureCanvas)

    this.signaturePad.addEventListener("afterUpdateStroke", () => {
        signatureFormField.value = this.signaturePad.toDataURL()
    })

    window.addEventListener("resize", this.resizeCanvas(signatureCanvas))

    this.resizeCanvas(signatureCanvas)

    this.clearButtonTarget.addEventListener("click", () => { this.signaturePad.clear() })

    if (existingSignature) {
      this.signaturePad.fromDataURL(existingSignature)
    }
  }

  resizeCanvas(canvas) {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext("2d").scale(ratio, ratio)
  }

  disconnect() {
    this.signaturePad.off()
  }
}
