import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const colorMode = window.matchMedia("(prefers-color-scheme: dark)")

    if (colorMode.matches) {
      this.element.dataset.bsTheme = "dark"
    } else {
      this.element.dataset.bsTheme = "light"
    }

    if (window.matchMedia) {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
        if (event.matches) {
          this.element.dataset.bsTheme = "dark"
        } else {
          this.element.dataset.bsTheme = "light"
        }
      })
    }
  }
}
