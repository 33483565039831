import { Controller } from "@hotwired/stimulus"
import Offcanvas from "bootstrap/js/dist/offcanvas"

export default class extends Controller {
  static targets = ["menu"]

  close() {
    const offCanvas = Offcanvas.getOrCreateInstance(this.menuTarget)
    offCanvas.hide()
  }
}
