import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  navigate() {
    const selectedId = this.element.value
    const urlParam = this.data.get("urlParam")
    var url = this.data.get("url")

    if (selectedId !== "") {
      if (urlParam) {
        const params = new URLSearchParams(location.search)
        params.set(urlParam, selectedId)

        const urlWithParams = `${location.pathname}?${params}`
        url = `${url}?${urlWithParams}`
      } else {
        url = url.replace(":id", selectedId)
      }
    }

    this.element.disabled = true
    this.element.classList.add("disabled")

    window.Turbo.visit(url)
  }
}
