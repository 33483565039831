import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    placeholder: { type: String, default: "{$}" },
    confirmMessage: { type: String, default: "Are you sure you want to remove this?" }
  }

  static targets = ["template", "record", "list"]

  connect() {
    if (!this.hasTemplateTarget) {
      console.warn("You must define a template target in order to use this controller")
    } else if (!this.hasListTarget) {
      console.warn("You must define a list target in order to use this controller")
    }
  }

  appendRecord(event) {
    event.preventDefault()

    const time = new Date().getTime()
    const html = this.templateTarget.innerHTML
    const updatedHtml = html.replaceAll(this.placeholderValue, time)

    this.listTarget.insertAdjacentHTML("beforeend", updatedHtml)
  }

  removeRecord(event) {
    event.preventDefault()

    const removeConfirmed = confirm(this.confirmMessageValue)

    if (removeConfirmed) {
      const target = event.target
      const recordId = target.dataset.recordId
      const record = document.getElementById(recordId)

      // We had to get clever here because we can't jsut remove the entire panel
      // If we do the following Stimulus actions that are potentially defined on this
      // event target will be lost because the element will no longer be in the DOM

      record.style.display = "none"

      const formFields = record.querySelector("[data-target='form-fields']")
      if (formFields) {
        formFields.remove()
      } else {
        console.log("you must define data-target='form-fields' on a container around the form fields to be removed")
      }
    } else {
      event.stopImmediatePropagation()
    }
  }
}
