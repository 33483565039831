import { Controller } from "@hotwired/stimulus"
// import { patch } from "@rails/request.js"

import { Calendar } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"

export default class extends Controller {
  static targets = ["calendar", "next", "prev", "today", "monthView", "resourceView"]

  connect() {
    const calendarEventSources = this.data.get("eventSources")
    const calendarResources = this.data.get("resources")
    const loadingIndicator = document.getElementById("loading-indicator")

    const updateEvent = function (info) {
      const event = info.event
      const eventType = event.extendedProps.eventType
      const resourceId = (info.newResource !== null) ? info.newResource.id : event.getResources().id
      const scheduledAt = new Date(event.start).toGMTString()
      const scheduledEndAt = new Date(event.end).toGMTString()

      let payload = ""
      if (eventType === "inspection") {
        payload = {
          inspection: {
            inspector_id: resourceId,
            scheduled_at: scheduledAt,
            scheduled_end_at: scheduledEndAt
          }
        }
      } else if (eventType === "work_order") {
        payload = {
          work_order: {
            assignee_id: resourceId,
            scheduled_at: scheduledAt
          }
        }
      }

      if (event.url === "") { return }

      patch(event.url, {
        contentType: "application/json",
        responseKind: "turbo-stream",
        body: JSON.stringify(payload)
      })
    }

    this.passCalendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimelinePlugin],
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      nowIndicator: true,
      initialView: "dayGridMonth",
      dayMaxEventRows: 3,
      businessHours: { start: "06:00", end: "18:00" },
      navLinkDayClick: "resourceTimelineDay",
      scrollTime: "00:00:00",
      snapDuration: "00:30",
      navLinks: true,
      headerToolbar: {
        start: "",
        center: "title",
        end: ""
      },
      eventSources: calendarEventSources.split(","),
      eventDisplay: "block",
      resources: calendarResources,
      refetchResourcesOnNavigate: true,
      resourceGroupField: "organization_name",
      resourceAreaWidth: "20%",
      loading: function (isLoading, view) {
        if (isLoading) {
          loadingIndicator.style.display = ""
        } else {
          loadingIndicator.style.display = "none"
        }
      },
      eventAllow(dropLocation, draggedEvent) {
        if (dropLocation.resourceId !== 0) {
          return true
        }
        return false
      },
      windowResize(view) {
        if (screen.width < 768) {
          this.changeView("timeGrid")
        }
      },
      eventResize(info) {
        updateEvent(info)
      },
      eventDrop(info) {
        updateEvent(info)
      }
    })

    document.passCalendar = this.passCalendar
    this.passCalendar.render()
    document.passCalendar.render()

    this.nextTarget.addEventListener("click", () => { this.passCalendar.next() })
    this.prevTarget.addEventListener("click", () => { this.passCalendar.prev() })
    this.todayTarget.addEventListener("click", () => { this.passCalendar.today() })

    this.monthViewTarget.addEventListener("click", () => { this.passCalendar.changeView("dayGridMonth") })
    this.resourceViewTarget.addEventListener("click", () => { this.passCalendar.changeView("resourceTimelineDay") })
  }

  disconnect() {
    this.passCalendar.destroy()
  }
}
