import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

export default class extends Controller {
  static targets = ["modal"]

  modalTargetConnected(element) {
    this.modal = new Modal(element, { backdrop: "static" })
    this.open()
  }

  modalTargetDisconnected() {
    this.close()
  }

  open() {
    this.modal.show()
  }

  close() {
    this.modal.hide()
  }

  closeOnSuccess(event) {
    if (event.detail.success) {
      this.close()

      const response = event.detail.fetchResponse.response
      const redirected = response.redirected

      if (redirected) {
        window.Turbo.visit(response.url)
      }
    }
  }

  redirect(event) {
    event.preventDefault()
    const response = event.detail.response
    window.Turbo.visit(response.url)
  }
}
