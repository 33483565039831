import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "submitButton", "dropzone", "form"]
  static values = {
    dropMessage: { type: String, default: "You can drag files here to upload" }
  }

  connect() {
    if (this.hasInputTarget) {
      Dropzone.autoDiscover = false
      this.inputTarget.disabled = true
      this.inputTarget.style.display = "none"

      if (this.hasSubmitButtonTarget) {
        this.disableSubmitButton()
      }

      this.uploadCounter = 0

      this.initializeDropzone()
    }
  }

  initializeDropzone() {
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      url: this.url,
      autoQueue: false,
      addRemoveLinks: false,
      parallelUploads: 100,
      dictDefaultMessage: this.dropMessageValue,
    })

    this.dropzone.on("addedfile", file => {
      this.uploadStarted()

      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url, this)
          upload.create((error, blob) => {
            if (error) {
              this.dropzone.emit("error", file, error)
            } else {
              this.hiddenInput = document.createElement("input")
              this.hiddenInput.setAttribute("type", "hidden")
              this.hiddenInput.setAttribute("value", blob.signed_id)
              this.hiddenInput.name = this.inputTarget.name
              this.dropzoneTarget.appendChild(this.hiddenInput)

              this.dropzone.emit("success", file)
            }

            this.dropzone.emit("complete", file)
            this.uploadComplete()
          })
        }
      }, 500)
    })
  }

  uploadComplete() {
    this.uploadCounter -= 1
    if (this.uploadCounter === 0) {
      if (this.hasSubmitButtonTarget) {
        this.enableSubmitButton()
      } else {
        this.formTarget.requestSubmit()
      }
    }
  }

  enableSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.remove("disabled")
      this.submitButtonTarget.disabled = false
    }
  }

  disableSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add("disabled")
      this.submitButtonTarget.disabled = true
    }
  }

  uploadStarted() {
    this.uploadCounter += 1
    if (this.hasSubmitButtonTarget) {
      this.disableSubmitButton()
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.element.querySelector("[data-dz-uploadprogress]").style.width = `${event.loaded * 100 / event.total}%`
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

}
