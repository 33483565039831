import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "element", "panel" ]

  elementTargetConnected(element) {
    this.scrollTo(element)
  }

  panelTargetConnected(element) {
    const pointer = element.querySelector(".pointer")

    element.classList.add("panel-attention")
    pointer.style.display = ""

    this.scrollTo(element)
  }

  scrollTo(element) {
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
}
