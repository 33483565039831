import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    filterParam: String
  }

  connect() {
    this.params = new URLSearchParams(location.search)
  }

  filter(event) {
    this.params.set(this.filterParamValue, event.target.value)

    // clear pagination, otherwise it causes a UI bug
    if (this.params.has("page")) { this.params.delete("page") }

    this.go()
  }

  go() {
    const url = `${location.pathname}?${this.params}`
    window.Turbo.visit(url)
  }
}
