import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stopper"]
  static values = {
    interval: { type: Number, default: 1000 * 5 }
  }

  connect() {
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  reload() {
    // Turbo offers a reload function https://turbo.hotwired.dev/reference/frames#functions
    this.element.reload()
  }

  stopperTargetConnected() {
    this.disconnect()
  }

  startRefreshing() {
    this.reload()
    this.refreshTimer = setInterval(() => {
      this.reload()
    }, this.intervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
