import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

export default class extends Controller {
  static targets = ["image", "originalImageButtonTarget", "title", "links", "loader"]
  static values = {
    imageUrl: String,
    extraLinks: String,
    originalImageUrl: String,
    title: String
  }

  show(event) {
    event.preventDefault()

    const modalEl = document.getElementById("image-modal")
    const title = modalEl.querySelector(".title")
    const links = modalEl.querySelector(".links")
    const imagePlaceholder = modalEl.querySelector(".image-placeholder")
    const originalImageButton = modalEl.querySelector(".original-image-button")

    this.modal = new Modal(modalEl, {
      keyboard: true,
    })

    const sanitizedTitle = this.titleValue.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "")
    title.innerHTML = sanitizedTitle

    if (this.hasLinksValue) {
      links.innerHTML = this.linksValue
    }

    if (this.hasOriginalImageUrlValue) {
      originalImageButton.style.display = "inline"
      originalImageButton.setAttribute("href", this.originalImageUrlValue)
    } else {
      originalImageButton.style.display = "none"
    }

    imagePlaceholder.innerHTML = imagePlaceholder.dataset.loader

    this.modal.show()

    const largerImage = new Image()
    largerImage.classList.add("rounded", "img-fluid", "center-block")
    largerImage.onload = function () {
      imagePlaceholder.innerHTML = this.outerHTML
    }

    largerImage.src = this.imageUrlValue
  }

  hide() {
    const modalEl = document.getElementById("image-modal")
    this.modal = Modal.getOrCreateInstance(modalEl)
    this.modal.hide()
  }
}
