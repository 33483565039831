import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["file", "preview", "successMessage"]

  connect() {
    this.fileTarget.addEventListener("change", event => {
      if (this.hasSuccessMessageTarget) { this.successMessageTarget.style.display = "none" }
      this.displayPreview()
      this.element.requestSubmit()
    })

    this.element.addEventListener("ajax:complete", event => {
      this.resetForm()
      this.displaySuccessMessage()
    })
  }

  openFileSelector() {
    this.fileTarget.click()
  }

  displayPreview() {
    if (this.hasPreviewTarget) {
      const file = this.fileTarget.files[0]
      const reader = new FileReader()

      reader.addEventListener("load", () => { this.previewTarget.src = reader.result }, false)

      if (file) { reader.readAsDataURL(file) }
    }
  }

  displaySuccessMessage() {
    this.successMessageTarget.style.display = ""
    setTimeout(() => { this.successMessageTarget.style.display = "none" }, 5000)
  }

  resetForm() {
    // We've got to remove the input field inserted by ActiveStorage direct_upload
    // in case someone wants to upload another file
    const uploadIndicators = Array.from(this.element.querySelectorAll(".direct-upload"))
    const fileFields = Array.from(this.element.querySelectorAll("input"))
      .filter(input => input.type == "hidden")
      .filter(input => input.name == this.fileTarget.name)

    const elementsToRemove = [...uploadIndicators, ...fileFields]

    elementsToRemove.forEach(element => { element.remove() })
    this.fileTarget.removeAttribute("disabled")
  }

}
