import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggler", "checkbox", "editButton", "form"]
  static values = {
    inputName: String
  }

  selectedCheckboxes() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked === true)
  }

  clicked() {
    this.toggleBUttonStatus()
    this.removeFieldsFromForm()
    this.selectedCheckboxes().forEach(checkbox => this.appendFieldToForm(checkbox))
  }

  toggleBUttonStatus() {
    if (this.selectedCheckboxes().length === 0) {
      this.editButtonTarget.classList.add("disabled")
    } else {
      this.editButtonTarget.classList.remove("disabled")
    }
  }

  removeFieldsFromForm() {
    const fieldName = `input[name="${this.inputNameValue}"`
    this.formTarget.querySelectorAll(fieldName).forEach(input => { input.remove() })
  }

  appendFieldToForm(checkbox) {
    const input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", this.inputNameValue)
    input.value = checkbox.value

    this.formTarget.appendChild(input)
  }

  toggleCheckboxes() {
    // toggler starts as false, so that's why we start the comparrison as 'false'
    const togglerStatus = this.togglerTarget.value === "false"

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = togglerStatus
    })

    const newStatus = this.togglerTarget.value === "false" ? "true" : "false"
    this.togglerTarget.value = newStatus

    this.formatButtons()
  }

  formatButtons() {
    const boxesSelectedCount = this.selectedCheckboxes().length

    if (boxesSelectedCount === 0) {
      this.editButtonTarget.innerHTML = this.editButtonTarget.dataset.defaultText
      this.togglerTarget.innerHTML = this.togglerTarget.dataset.defaultText
    } else {
      this.editButtonTarget.innerHTML = `Edit ${boxesSelectedCount} Selected...`
      this.togglerTarget.innerHTML = this.togglerTarget.dataset.selectedText
    }
  }
}
