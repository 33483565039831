import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "searchField", "entry", "clearButton" ]

  filter() {
    const searchTerm = this.searchFieldTarget.value.toLowerCase()

    if (searchTerm === "") {
      this.clearButtonTarget.classList.add("disabled")
      this.entryTargets.forEach(entry => { entry.style.display = "" })
    } else {
      this.clearButtonTarget.classList.remove("disabled")
      this.entryTargets.forEach(entry => {
        const formattedEntryText = entry.textContent.toLowerCase()
        if (formattedEntryText.search(searchTerm) > 0) {
          entry.style.display = ""
        } else {
          entry.style.display = "none"
        }
      })
    }
  }

  clear() {
    this.entryTargets.forEach(entry => { entry.style.display = "" })
    this.searchFieldTarget.value = ""
    this.clearButtonTarget.classList.add("disabled")
  }
}
