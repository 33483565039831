import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()
    var text

    if (this.sourceTarget.value === undefined) {
      text = this.sourceTarget.innerText
    } else {
      text = this.sourceTarget.value
    }

    navigator.clipboard.writeText(text)
  }
}
