import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = {
    apiKey: String, // Add your Google Maps API Key as a value
    lat: Number,    // Latitude for the map's initial position
    long: Number,    // Longitude for the map's initial position
    title: String,  // Title for the marker
    content: String,  // Content for the marker
    zoom: { type: Number, default: 14 } // Default zoom level
  }

  connect() {
    if (typeof google === "undefined") {
      this.loadGoogleMapsAPI().then(() => {
        this.initializeMap()
      }).catch((error) => {
        console.error("Google Maps failed to load:", error)
      })
    } else {
      this.initializeMap() // If already loaded
    }
  }

  // Load Google Maps API dynamically
  loadGoogleMapsAPI() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&callback=initMap`
      script.async = true
      script.defer = true

      // Resolve the promise when Google Maps API is loaded
      window.initMap = () => resolve()
      script.onerror = reject

      document.head.appendChild(script)
    })
  }

  // Initialize the Google Map
  initializeMap() {
    const mapOptions = {
      center: { lat: this.latValue, lng: this.longValue },
      zoom: this.zoomValue
    }

    this.map = new window.google.maps.Map(this.mapTarget, mapOptions)

    const marker = new window.google.maps.Marker({
      position: { lat: this.latValue, lng: this.longValue },
      map: this.map,
      title: this.titleValue
    })

    const infoWindow = new window.google.maps.InfoWindow({ content: this.contentValue })

    window.google.maps.event.addListener(marker, "click", () => { infoWindow.open(this.map, marker) })
  }
}
