// Setup Stimulus
import "./controllers"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "@hotwired/turbo-rails"

import LocalTime from "local-time"
LocalTime.start()
window.LocalTime = LocalTime

import Pagy from "./pagy"
window.addEventListener("turbo:load", Pagy.init)

import "chartkick/chart.js"
// added because charts won't load if they are on the intial page loaded
// https://github.com/ankane/chartkick/pull/610
if ("Chartkick" in window) {
  window.addEventListener("turbo:load", Chartkick.createChart, { once: true });
}

import "bootstrap/js/dist/alert"
import "bootstrap/js/dist/button"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/tab"

// App Specific
import "./bootstrap/remote_flash_messages"

import "./activestorage/direct-upload"
import "./network-status"
import "./stripe-setup"

// apparently has to be after Stimulus to load properly
// had to pin tom-select to 2.3.1 because it breaks trix right now
import "trix"
import "@rails/actiontext"

// protect ActionText upload types
// https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6/
window.addEventListener("trix-file-accept", function (event) {
  const acceptedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"]
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only image files and PDFs can be included as attachments")
  }
})
