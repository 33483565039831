import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    searchTerm: { type: String }
  }

  connect() {
    const searchTerm = this.searchTermValue?.trim()?.toLowerCase()
    const searchArea = this.element

    if (CSS.highlights) {

      CSS.highlights.clear()

      if (searchTerm) {
        // Find all text nodes in the searchArea element.
        const treeWalker = document.createTreeWalker(searchArea, NodeFilter.SHOW_TEXT)
        const allTextNodes = []
        let currentNode = treeWalker.nextNode()
        while (currentNode) {
          allTextNodes.push(currentNode)
          currentNode = treeWalker.nextNode()
        }

        const ranges = allTextNodes
          .map((el) => {
            return { el, text: el.textContent.toLowerCase() }
          })
          .filter(({ text }) => text.includes(searchTerm))
          .map(({ text, el }) => {
            // Find all instances of searchTerm in el.textContent
            const indices = []
            let startPos = 0
            while (startPos < text.length) {
              const index = text.indexOf(searchTerm, startPos)
              if (index === -1) break
              indices.push(index)
              startPos = index + searchTerm.length
            }

            return indices.map((index) => {
              const range = new Range()
              range.setStart(el, index)
              range.setEnd(el, index + searchTerm.length)
              return range
            })
          })

        const highlight = new Highlight(...ranges.flat())
        CSS.highlights.set("search-result-highlight", highlight)
      }
    } else {
      console.info("CSS Hightlights not supported on this browser")
    }
  }

  disconnect() {
    if (CSS.highlights) {
      CSS.highlights.clear()
    }
  }
}
