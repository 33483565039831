import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "startVolume", "endVolume", "deliveriesVolume", "salesVolume", "differenceVolume"
  ]

  calculate() {
    const startVolume = parseInt(this.startVolumeTarget.value)
    const deliveriesVolume = parseInt(this.deliveriesVolumeTarget.value)
    const endVolume = parseInt(this.endVolumeTarget.value)
    const salesVolume = parseInt(this.salesVolumeTarget.value)

    const difference = endVolume - ((startVolume + deliveriesVolume) - salesVolume)

    this.differenceVolumeTarget.innerHTML = isNaN(difference) ? "-" : difference
  }
}
