import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.refresh(event)
    })
  }

  refresh(event) {
    if (event.detail.success) {
      document.passCalendar.refetchEvents()
    }
  }
}
