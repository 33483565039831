function showFlashMessage(alert, timeout = true) {
  const flashContainer = document.getElementById("flash-messages")
  const alertId = `alert-${new Date().getTime()}`

  flashContainer.insertAdjacentHTML("beforeend", `<span id="${alertId}">${alert}</span>`)

  if (timeout === true) {
    setTimeout(() => {
      document.getElementById(alertId).remove()
    }, 5000)
  }
}

window.showFlashMessage = showFlashMessage
