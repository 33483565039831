import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["file"]

  async shareUrl(event) {
    event.preventDefault()
    if (navigator.share) {
      try {
        await navigator.share({
          url: this.element.href,
          title: this.element.innerHTML
        })
        console.log("Starting sharing")
      } catch (err) {
        console.log("Error: " + err)
      }
    } else {
      console.log("You can't share")
      console.log(event)
    }
  }
}
