import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchBar", "form"]

  connect() {
    this.params = new URLSearchParams(location.search)
  }

  search(event) {
    event.preventDefault()

    this.resetPagination()

    this.params.set("search", this.searchBarTarget.value)

    this.go()
  }

  focus(event) {
    event.preventDefault()
    this.searchBarTarget.focus()
  }

  clear() {
    this.resetPagination()

    this.params.delete("search")
    this.searchBarTarget.value = ""

    this.go()
  }

  resetPagination() {
    // clear pagination, otherwise it causes a UI bug
    if (this.params.has("page")) { this.params.delete("page") }
  }

  go() {
    const url = this.params.size == 0 ? location.pathname : `${location.pathname}?${this.params}`
    window.Turbo.visit(url)
  }
}
