import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "submitButton", "saveIndicator"]
  static classes = ["valid", "invalid"]

  submit(event) {
    this.removesubmitButton()
    this.showSaveIndicator()

    this.formTarget.addEventListener("direct-uploads:end", () => {
      this.formTarget.requestSubmit()
    })

    this.formTarget.requestSubmit()
    this.formTarget.disabled = true

  }

  validate(event) {
    const input = event.target
    const isFlatpickrInput = input.classList.contains("flatpickr")
    const flatPickerInput = input.nextSibling


    if (input.value === "") {
      input.classList.remove(this.validClass)
      input.classList.add(this.invalidClass)

      if (isFlatpickrInput) {
        flatPickerInput.classList.remove(this.validClass)
        flatPickerInput.classList.add(this.invalidClass)
      }
    } else {
      input.classList.remove(this.invalidClass)
      if (isFlatpickrInput) {
        flatPickerInput.classList.remove(this.invalidClass)
      }
    }
  }

  showsubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTargets.forEach(button => button.style.display = "")
    }
  }

  removesubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTargets.forEach(button => button.remove())
    }
  }

  showSaveIndicator() {
    if (this.hasSaveIndicatorTarget) {
      this.saveIndicatorTargets.forEach(indicator => indicator.style.display = "")
    }
  }
}
